import React from 'react'

import Layout from '../layout'
import SEO from '../utils/seo'
import ContainerRegions from '../assets/styles/pages/regions'
import BannerDivider from '../components/bannerDivider'

const RegionsPage = () => (
    <Layout>
        <SEO title="Regiões" />
        <BannerDivider title="Regiões de Atendimento" subtitle="Conduta ética e profissional" />

        <ContainerRegions className="col-vw-7">
            <div className="backgroundContentRegions mt-3px">
                <h1>Regiões de atendimento</h1>
            </div>

            <iframe
                title="regionsFrame"
                src="https://www.google.com/maps/d/u/3/embed?mid=1W3DfFI-1quSBSjmGdkO_djujWVTqnj5I"
                className="map-regions"
            />
        </ContainerRegions>
    </Layout>
)

export default RegionsPage
