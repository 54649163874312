import styled from 'styled-components'

export default styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .backgroundContentRegions {
        align-self: flex-start;
        padding: 5px 20px;
        background: #393737;
        color: #f0ebeb;
        width: 60%;
    }

    .map-regions {
        margin: 30px 0;
        width: 70vw;
        height: 450px;
        box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    }

    @media only screen and (max-width: 798px) {
        .backgroundContentRegions {
            width: 80%;
        }

        .map-regions {
            width: 90vw;
        }
    }
`
